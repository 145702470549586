var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "content content-full"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-3"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Report Download")]), _c('div', [_vm._t("actions")], 2)]), _c('div', {
    attrs: {
      "data-cy": "report-download"
    }
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('div', [_c('div', {
    staticClass: "row justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center mb-5"
  }, [_vm.loading || _vm.report.status === 'preparing' ? _c('Spinner', [_vm._v("Preparing report... This may take a few minutes.")]) : _vm.report.status === 'generating' ? _c('Spinner', [_vm._v("Generating report... This may take a few minutes.")]) : _vm.report.status === 'ready' ? _c('div', [_c('div', {
    staticClass: "font-w600 font-size-h4 mb-3"
  }, [_c('i', {
    staticClass: "fa fa-check-circle text-success mr-2"
  }), _vm._v(" Report ready!")]), _c('p', [_vm._v(_vm._s(_vm.report.fileName))]), _c('a', {
    staticClass: "btn btn-primary btn-lg",
    class: {
      disabled: !_vm.downloadUrl
    },
    attrs: {
      "href": _vm.downloadUrl
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-download"
  }), _vm._v(" Download")])]) : _vm.report.status === 'error' ? _c('div', [_c('div', {
    staticClass: "font-w600 font-size-h4 mb-2"
  }, [_c('i', {
    staticClass: "fa fa-times-circle text-danger"
  }), _vm._v(" Report failed to generate.")]), _c('div', [_vm._v("Please contact support.")])]) : _vm._e()], 1), _vm.additionalParams.length > 0 ? _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', [_c('h3', {
    staticClass: "font-size-h5 mb-2 text-muted"
  }, [_vm._v("Report Parameters")]), _c('p', [_vm._v(" These are the parameters that were used to generate this report. These will affect the data within the report, such as consumption values. ")]), _c('ul', {
    staticClass: "list-group"
  }, _vm._l(_vm.additionalParams, function (additionalParam) {
    return _c('li', {
      key: additionalParam.key,
      staticClass: "list-group-item"
    }, [_c('strong', [_vm._v(_vm._s(additionalParam.name) + " | ")]), _vm._v(" " + _vm._s(_vm._f("capitalize")(additionalParam.value.toString())) + " ")]);
  }), 0)])]) : _vm._e()])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }